import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { UiSizes } from "../../../../../../enums";
import I18n from "../../../../../localization/I18n";
import { UiActionRenderers } from "../../../../../uiAction/IUiAction";
import { GridActionEnum, GridLinkActionEnum } from "../../../enums/GridActionEnum";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
// import { SHOW_ADD_PROCESSES_FROM_TEMPLATE_MODAL } from "./ProjectProcessesGridView_modals";
import { ProjectProcessGridModel } from "./ProjectProcessesGridView_model";

export const GetProjectProcessesGridActions = (model: ProjectProcessGridModel): IGridUiAction[] => {
  return [
    {
      id: GridActionEnum.import,
      label: I18n.t("phrases.import"),
      type: "uiAction",
      onAction: ev => model.showCoreProcessesLinkModal(),
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.CircleDownArrow
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.link,
      label: (
        <>
          <Icon symbol={IconSymbols.Link} size={UiSizes.MD} />
          <Icon symbol={IconSymbols.ChevronDown} size={UiSizes.SM} />
        </>
      ),
      type: "uiAction",
      componentProps: {
        type: ButtonTypes.LINK,
        className: "p-1"
      },
      disabledWhenNoItemSelected: true,
      rendersIn: UiActionRenderers.BUTTON,
      children: [
        {
          id: GridLinkActionEnum.linkImpacts,
          label: I18n.t("entities.impacts"),
          type: "uiAction",
          onAction: async ev => {
            model.showLinkToImpacts();
          },
          componentProps: {
            type: ButtonTypes.LINK
          },
          disabledWhenNoItemSelected: true,
          rendersIn: UiActionRenderers.BUTTON
        },
        {
          id: GridLinkActionEnum.linkLocations,
          label: I18n.t("phrases.locations"),
          type: "uiAction",
          onAction: ev => model.showLinkToLocations(),
          componentProps: {
            type: ButtonTypes.LINK
          },
          disabledWhenNoItemSelected: true,
          rendersIn: UiActionRenderers.BUTTON
        },
        {
          id: GridLinkActionEnum.linkProcessRoles,
          label: I18n.t("entities.processRoles"),
          type: "uiAction",
          onAction: ev => model.showLinkToProcessRoles(),
          componentProps: {
            type: ButtonTypes.LINK
          },
          disabledWhenNoItemSelected: true,
          rendersIn: UiActionRenderers.BUTTON
        }
      ]
    },
    {
      id: GridActionEnum.delete,
      label: I18n.t("phrases.delete"),
      type: "uiAction",
      onAction: ev => model.showProcessConfirmDeleteModal(),
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Trash
      },
      disabledWhenNoItemSelected: true,
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: "devider",
      label: "",
      type: "devider",
      onAction: ev => {},
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.textWrap,
      label: I18n.t("phrases.textWrap"),
      type: "uiAction",
      onAction: ev => {
        model.textWrapAddon?.toggleTextWrap();
        model.generateActions();
      },
      componentProps: {
        type: model.textWrapAddon?.isWrappedText ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY,
        symbol: IconSymbols.TextWrap
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.clearFilters,
      label: I18n.t("phrases.clearFilters"),
      type: "uiAction",
      disabledWhenNoItemSelected: false,
      onAction: ev => {
        model.filterStoreAddon.clearFilters();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.ClearFilter,
        isDisabled: !model.isFilterChanged
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.resetColumns,
      label: I18n.t("phrases.resetColumns"),
      type: "uiAction",
      disabledWhenNoItemSelected: false,
      onAction: ev => {
        model.columnStateAddon.resetColumns();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.MonitorBack,
        isDisabled: !model.isColumnStateChanged
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    }
  ];
};
