import { action, makeObservable, observable } from "mobx";
import AudiencesApi from "../../../../../services/api/v2/audiences/Audiences.api";

export class AudiencesGridSidebarModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable data: FP.Entities.IAudienceSummary[] = [];
  audiencesProvider = AudiencesApi;
  @observable selectedItemIds: number[] = [];
  /**
   *
   */
  constructor(organisationId: number, projectId: number, selectedItemIds: number[]) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.selectedItemIds = selectedItemIds;
  }

  onMount = async () => {
    await this.loadAudiences();
  };

  loadAudiences = async () => {
    let res = await this.audiencesProvider.getGridData(this.organisationId, this.projectId);

    if (res.isError) return;

    this.setData(res.payload);
    this.setIsLoading(false);
  };

  @action
  setData = (data: FP.Entities.IAudienceSummary[]) => {
    this.data = data;
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
