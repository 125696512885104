import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../components/ui/Panel";
import I18n from "../../../../../localization/I18n";
import ModalContext from "../../../../../modalZ/context/ModalContext";

export const SHOW_PROCESS_ROLE_DELETE_CONFIRM_MODAL = (
  itemIds: number[],
  removeItems: (itemIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    ModalContext.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeProcessFromOrg")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmProcessRangeRemove", { name: itemIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        ModalContext.hide();
        await removeItems(itemIds);
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.DANGER
    );
  });
};
