import React from "react";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle
} from "../../../../../components/ui/ReviewModal";
import {
  gridSideModalAnimationOpts,
  gridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../../core/grids/GridSideModals";
import I18n from "../../../../../core/localization/I18n";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { StakeholderImpactsSidebar } from "./StakeholderGridView/modals/impactsSidebar/StakeholderImpactsSidebar_view";
import { StakeholderNotesSidebar } from "./StakeholderGridView/modals/notesSidebar/StakeholderNotesSidebar";
import { StakeholderTagsSidebar } from "./StakeholderGridView/modals/tagsSidebar/StakeholderTagsSidebar_view";
import { UnassignedStakeholdersGrid } from "../UnassignedStakeholdersGrid/UnassignedStakeholdersGrid_view";
import { Animations } from "../../../../../core/util/Animations";
import { StakeholderType } from "../../../../../enums";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import { StakeholderEmail } from "./StakeholderGridView/modals/stakeholderEmail/StakeholderEmail_view";
import { StakeholderOwnersSidebar } from "./StakeholderGridView/modals/ownersSidebar/StakeholderOwnersSidebar_view";
import { ActionSidebar } from "./StakeholderGridView/modals/actionSidebar/ActionSidebar_view";
import { ActionGrid } from "../../../../../core/grids/commonGrids/ActionGrid/ActionGrid_view";
import { WrapperImpactGridWithDataLoader } from "../../../../../core/grids/builder/directorBuilder/grids/ImpactsGrid/WrapperImpactsGridWithDataLoader_view";

export const SHOW_STAKEHOLDER_EXISTS_MODAL = (
  modalService: IModalContextModel,
  res,
  stakeholder,
  activateExistingStakeholder,
  addProjectStakeholder
) => {
  modalService.showConfirmDialog(
    <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
    <div className="container-fluid">
      <div className="row">
        <div className="col">{I18n.t("phrases.stakeholderExists")}</div>
      </div>
    </div>,
    I18n.t("phrases.bringBackExisting"),
    I18n.t("phrases.createNew"),
    {
      wrapWidth: "small",
      spacing: "small",
      position: "middle",
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE
      }
    },
    async () => {
      activateExistingStakeholder(res.payload);
      modalService.hide();
    },
    () => {
      addProjectStakeholder(stakeholder);
      modalService.hide();
    }
  );
};

export const SHOW_STAKEHOLDER_DELETE_CONFIRM_MODAL = (
  modalService: IModalContextModel,
  projectStakeholderIds: number[],
  deleteRange
) => {
  return new Promise(resolve => {
    modalService.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeStakeholderFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {I18n.t("phrases.confirmStakeholderRangeRemove", { name: projectStakeholderIds.length })}
          </div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        modalService.hide();
        await deleteRange();
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_STAKEHOLDER_REVIEW_CONFIRM_MODAL = (
  modalService: IModalContextModel,
  projectStakeholderIds: number[],
  reviewStakeholderRange: (projectStakeholderIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    modalService.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewStakeholderRange(projectStakeholderIds, comment);
        modalService.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const SHOW_STAKEHOLDER_IMPACT_MODAL =
  (modalService: IModalContextModel) => (projectStakeholder: FP.Entities.IProjectStakeholderSummary) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={projectStakeholder.name} field={I18n.t("phrases.associatedDetailedImpacts")} />,
      content: (
        <StakeholderImpactsSidebar
          modalService={modalService}
          projectId={projectStakeholder.projectId}
          stakeholderId={projectStakeholder.stakeholderId}
        />
      ),
      componentProps: { ...gridSideModalComponentProps, className: "modal__grid-link" },
      animationOptions: gridSideModalAnimationOpts
    });
  };

export const SHOW_ACTION_STAKEHOLDER_OWNER_MODAL =
  (modalService: IModalContextModel, projectId: number) =>
  (projectStakeholder: FP.Entities.IProjectStakeholderSummary) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={projectStakeholder.name} field={I18n.t("phrases.associatedDetailedImpacts")} />,
      content: <ActionSidebar projectId={projectId} projectStakeholderId={projectStakeholder.id} />,
      componentProps: { ...gridSideModalComponentProps, className: "modal__grid-link" },
      animationOptions: gridSideModalAnimationOpts
    });
  };

export const SHOW_STAKEHOLDER_NOTES_MODAL = (
  modalService: IModalContextModel,
  projectStakeholder: FP.Entities.IProjectAudienceSummary
) => {
  modalService.show({
    showClose: true,
    title: <GridSideModalTitle name={projectStakeholder.name} field={I18n.t("phrases.tags")} />,
    content: (
      <StakeholderNotesSidebar
        projectId={projectStakeholder.projectId}
        stakeholderId={projectStakeholder.stakeholderId}
      />
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};

export const SHOW_STAKEHOLDER_TAGS_MODAL = (
  modalService: IModalContextModel,
  audience: FP.Entities.IProjectAudienceSummary
) => {
  modalService.show({
    showClose: true,
    title: <GridSideModalTitle name={audience.name} field={I18n.t("phrases.tags")} />,
    content: <StakeholderTagsSidebar projectId={audience.projectId} stakeholderId={audience.stakeholderId} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
export const SHOW_STAKEHOLDER_OWNER_ACTIONS_MODAL = (
  modalService: IModalContextModel,
  projectId: number,
  projectStakeholder: any
) => {
  modalService.show({
    showClose: true,
    title: <GridSideModalTitle name={projectStakeholder.name} field={I18n.t("phrases.ownerNames")} />,
    content: (
      <StakeholderOwnersSidebar
        projectId={projectId}
        stakeholderId={projectStakeholder.stakeholderId}
        projectStakeholderId={projectStakeholder.id}
      />
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};

export const SHOW_STAKEHOLDER_LINK_MODAL = (
  modalService: IModalContextModel,
  projectId: number,
  stakeholderType: StakeholderType
) => {
  modalService.show({
    showClose: false,
    title: null,
    content: (
      <UnassignedStakeholdersGrid
        stakeholderType={stakeholderType}
        projectId={projectId}
        onAssignSuccess={modalService.hide}
      />
    ),
    componentProps: { ...gridSideModalComponentProps, wrapHeight: "default", wrapWidth: "large", position: "middle" },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_STAKEHOLDER_IMPACT_LINK_MODAL = (
  modalService: IModalContextModel,
  onAssignSuccess: (selectedImpacts: number[]) => void,
  stakeholderIds: number[]
) => {
  modalService.show({
    showClose: false,
    title: null,
    content: <WrapperImpactGridWithDataLoader selectedLinkItems={stakeholderIds} onAssignSuccess={onAssignSuccess} />,
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_STAKEHOLDER_EMAILS = (
  modalService: IModalContextModel,
  stakeholders: FP.Entities.IProjectStakeholderSummary[]
) => {
  modalService.show({
    showClose: true,
    title: null,
    content: <StakeholderEmail stakeholders={stakeholders} />,
    componentProps: { ...gridSideModalComponentProps, wrapHeight: "default", wrapWidth: "large", position: "middle" },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_STAKEHOLDER_ACTION_LINK_MODAL = (
  modalService: IModalContextModel,
  projectId: number,
  assignActionsToImpacts: (actionIds: number[]) => void
) => {
  modalService.show({
    showClose: false,
    title: null,
    content: <ActionGrid projectId={projectId} onAssignSuccess={assignActionsToImpacts} />,
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};
