import { useEffect, useState } from "react";
import { ProcessesGrid } from "../../../../../core/grids/builder/directorBuilder/grids/ProcessesGrid/ProcessesGridView_view";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProcessesSettingsModel } from "./ProcessesSettings_model";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { Tab, Tabs } from "../../../../../components/ui/Tabs";
import I18n from "../../../../../core/localization/I18n";
import "./_processes-settings.scss";
import { ProcessRolesGrid } from "../../../../../core/grids/builder/directorBuilder/grids/ProcessRolesGrid/ProcessRolesGridView_view";

export const ProcessesSettings: React.FC = observer(() => {
  let organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ProcessesSettingsModel(organisationId));

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <Tabs
      key="first"
      className="tabs--primary processes-settings"
      ulClassName="tabs__menu--primary"
      initialTab={0}
      liClassName="tabs__item--primary"
    >
      <Tab title={<h2 className="mb-0">{I18n.t("entities.processes")}</h2>}>
        <div className=" insight-tab-content__space-filler" style={{ height: "100%" }}>
          <ProcessesGrid
            data={model.processData}
            gridToolbarType="base"
            onFieldUpdate={model.loadProcessData}
            refreshDataHandler={model.loadProcessData}
          />
        </div>
      </Tab>

      <Tab title={<h2 className="mb-0">{I18n.t("entities.processRoles")}</h2>}>
        <ProcessRolesGrid
          data={model.processRoleData}
          gridToolbarType="base"
          onFieldUpdate={model.loadProcessRoleData}
          refreshDataHandler={model.loadProcessRoleData}
        />
      </Tab>
    </Tabs>
  );
});
