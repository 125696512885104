import { AxiosInstance, AxiosRequestConfig } from "axios";
import http from "../Http";
import { ProcessHierarchField } from "../../../../core/grids/builder/directorBuilder/utils/GridFields";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { ExportableOrganisationApi } from "../exportableApi/ExportableOrganisationApiModel";

export class ProcessRolesApi extends ExportableOrganisationApi<any> {
  controller: string = "process-roles";

  constructor(http: AxiosInstance) {
    super(http, "process-roles");
  }

  updateField = async (
    organisationId: number,
    projectId: number = null,
    processId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    processField: ProcessHierarchField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/${this.controller}/${processId}/${processField}`,
      data,
      config
    );
    return res.data;
  };

  getAll = async (organisationId: number, config?: any) => {
    let res = await this.http.get(`${this.url}/${organisationId}/${this.controller}`, config);
    return res.data;
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(`${this.url}/${organisationId}/${this.controller}/delete-range`, ids, config);
    return res.data;
  };
}

const instance = new ProcessRolesApi(http);
export default instance;
