import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { IF } from "../../../../../../components/hoc/If";
import ProcessRolesApi from "../../../../../../services/api/v2/processRoles/ProcessRoles.api";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProcessRolesGrid } from "./ProcessRolesGridView_view";

class WrapperProcessRolesGridWithDataLoaderModel {
  @observable isLoading = true;
  @observable data = [];
  processRolesProvider = ProcessRolesApi;
  organisationId: number;

  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
  }

  onMount = async () => {
    await this.loadData();
  };

  loadData = async () => {
    let res = await this.processRolesProvider.getAll(this.organisationId);

    if (res.isError) {
      return;
    }

    this.setData(res.payload);
    this.setIsLoading(false);
  };

  @action
  setData = (data: any[]) => (this.data = data);

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}

export const WrapperProcessRolesGridWithDataLoader: React.FC<{ selectedLinkItems: any[]; onAssignSuccess: any }> =
  observer(({ onAssignSuccess, selectedLinkItems }) => {
    const organisationId = useCurrentOrganisationId();

    const [model] = useState(() => new WrapperProcessRolesGridWithDataLoaderModel(organisationId));
    useEffect(() => {
      model.onMount();
      // eslint-disable-next-line
    }, []);

    return (
      <>
        <IF condition={model.isLoading}>
          <PositionedSpinner></PositionedSpinner>
        </IF>
        <IF condition={!model.isLoading}>
          <ProcessRolesGrid
            gridToolbarType="link-modal"
            onAssignSuccess={onAssignSuccess}
            selectedLinkItems={selectedLinkItems}
            data={model.data}
          />
        </IF>
      </>
    );
  });
