import React, { useState } from "react";
import { getImpactStakeholderFormFields } from "./getStakeholderFormFields";
import I18n from "../../../../../core/localization/I18n";
import { StakeholderType } from "../../../../../enums";
import ImpactsApi from "../../../../../services/api/v2/impacts/Impacts.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";

interface AddStakeholderFormProps {
  organisationId: number;
  projectId: number;
  impactId: number;
  stakeholderType: StakeholderType;
  setIsActive: (e?) => void;
  reloadImpactFn: (id: number) => void;
}

export const AddStakeholderForm: React.FC<AddStakeholderFormProps> = ({
  reloadImpactFn,
  setIsActive,
  organisationId,
  projectId,
  impactId,
  stakeholderType
}) => {
  const [model] = useState(
    () => new AddStakeholderFormModel(reloadImpactFn, setIsActive, projectId, impactId, organisationId, stakeholderType)
  );

  return (
    <div className="mt-2">
      <SingleForm model={model.formModel} />
    </div>
  );
};

export class AddStakeholderFormModel {
  formModel: SingleFormModel;

  /**
   *
   */
  constructor(
    reloadImpactFn: (id: number) => void,
    setIsActive: (e?) => void,
    projectId,
    impactId,
    organisationId,
    stakeholderType
  ) {
    const impactsProvider = ImpactsApi;
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactStakeholderFormFields(
      impactsProvider,
      projectId,
      impactId,
      organisationId,
      stakeholderType
    );
    this.formModel.actions = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          setIsActive(false);
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await this.formModel.submit();
          if (!res) return;
          await impactsProvider.addProjectStakeholder(organisationId, projectId, impactId, res.stakeholders);
          reloadImpactFn(impactId);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
  }
}
