import { useLocation, useParams } from "react-router-dom";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useEffect, useState } from "react";
import {
  InsightTabContent,
  InsightTabContentItem
} from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react-lite";

export const AudienceTabContent: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";
    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const items: InsightTabContentItem[] = [
    {
      label: tabHeadingWithCount(I18n.t("entities.audiences")),
      url: Pages.projects.audiences.listView.generateLink(organisationId, +projectId)
    }
  ];
  if (isVisualisationsEnabled) {
    items.push({
      label: tabHeadingWithCount(I18n.t("phrases.audienceMap")),
      url: Pages.projects.audiences.visualisations.index.generateLink(organisationId, +projectId)
    });
  }

  items.push({
    label: tabHeadingWithCount(I18n.t("phrases.readinessTracking")),
    url: Pages.projects.audiences.readinessTrackingView.generateLink(organisationId, +projectId)
  });
  items.push({
    label: tabHeadingWithCount(I18n.t("phrases.readinessCharts")),
    url: Pages.projects.audiences.readinessChartsView.generateLink(organisationId, +projectId)
  });

  const location = useLocation();
  const currentPath = location.pathname;
  const [currIndex, setCurrIndex] = useState(getCurrentIndex(currentPath));

  useEffect(() => {
    setCurrIndex(getCurrentIndex(location.pathname));
  }, [location]);

  return <InsightTabContent items={items} currentIndex={currIndex} />;
});

const getCurrentIndex = path => {
  if (path.endsWith("audiences")) {
    return 0;
  } else if (path.endsWith("audiences/readiness-tracking")) {
    return 2;
  } else if (path.endsWith("audiences/readiness-charts")) {
    return 3;
  } else if (
    path.endsWith("audiences/visualisations") ||
    path.endsWith("audiences/visualisations/awareness") ||
    path.endsWith("audiences/visualisations/understanding") ||
    path.endsWith("audiences/visualisations/capability") ||
    path.endsWith("audiences/visualisations/commitment") ||
    path.endsWith("audiences/visualisations/adoption")
  ) {
    return 1;
  }
};
