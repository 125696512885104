import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { ButtonTypes } from "../../../../components/ui/Button";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import {
  GridSideModalTitle,
  gridSideModalAnimationOpts,
  gridSideModalComponentProps
} from "../../../../core/grids/GridSideModals";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { IUiAction, UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../core/util/BaseModel";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";
import { ImpactActionSidebar } from "./impactActionSidebar/ImpactActionSidebar_view";
import Pages from "../../../../routes/InsightRoutes";

export class ImpactActionsModel extends BaseModel {
  impactProvider: IImpactsApi;
  isRouteView: boolean;
  impactId: number;
  projectId: number;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable isLoading: boolean = true;
  @observable toggle: "active" | "closed" = "active";
  @observable.ref impact: FP.Entities.IImpact;
  @observable.ref impactActions: FP.Entities.IAction[] = [];
  @observable.ref filteredActions: FP.Entities.IAction[] = [];
  actionCardActions: IUiAction<FP.Entities.IAction>[] = [];
  organisationId: number;
  isFromGridView: boolean;

  constructor(
    organisationId: number,
    projectId: number,
    impactId: number,
    impact: FP.Entities.IImpact,
    isRouteView: boolean = true,
    isFromGridView: boolean = false
  ) {
    super();
    makeObservable(this);
    this.impactProvider = ImpactsApi;
    this.isRouteView = isRouteView;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.modalService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.impactId = impactId;
    this.impact = impact;
    this.isFromGridView = isFromGridView;
    this.setActionCardActions();
  }

  onMount = (orgId: number) => {
    this.organisationId = orgId;
  };

  onUnmount = () => {
    this.resetSearch();
  };

  loadActions = async (impactId: number) => {
    this.impactId = impactId;
    this.isLoading = true;
    let res = await this.impactProvider.getActions(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setActions(res.payload);
  };

  setActionCardActions = () => {
    const canEditActions = PermissionsContext.canEditField(
      PermissionFields.ACTIONS,
      this.organisationId,
      this.projectId
    );
    this.actionCardActions.push({
      id: "action1",
      label: I18n.t("phrases.view"),
      rendersIn: UiActionRenderers.HTML_ANCHOR,
      componentProps: {
        type: ButtonTypes.LINK
      },
      hrefFn: action => Pages.projects.actions.extendedView.generateLink(this.organisationId, this.projectId, action.id)
    });
    if (canEditActions) {
      this.actionCardActions.push({
        id: "action2",
        label: I18n.t("phrases.remove"),
        onAction: async (ev, action: FP.Entities.IAction) => {
          await this.removeAction(this.impactId, action.id);
          this.loadActions(this.impactId);
        },
        componentProps: {
          type: ButtonTypes.LINK
        },
        rendersIn: UiActionRenderers.BUTTON
      });
    }
  };

  @action
  setActions = (actions: FP.Entities.IAction[]) => {
    this.impactActions = actions;
    this.filterActions();
    this.isLoading = false;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterActions();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterActions();
    this.hideSearchMode();
  };

  @action
  filterActions = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredActions = this.impactActions.filter(action => {
        const lowerName = `${action.owner.firstName} ${action.owner.lastName}`.toLowerCase();
        const lowerContent = action.name.toLowerCase();
        const lowerRefNo = action.refNumber.toLowerCase();

        return (
          lowerName.includes(lowerSearch) || lowerContent.includes(lowerSearch) || lowerRefNo.includes(lowerSearch)
        );
      });
    } else {
      this.filteredActions = this.impactActions;
    }
  };

  removeAction = async (impactId: number, actionId: number) => {
    this.httpProgress.showOverlay();
    const res = await this.impactProvider.removeAction(this.organisationId, this.projectId, impactId, actionId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    return res.payload;
  };

  showAddActionModal = () => {
    this.modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={this.impact?.name} field={I18n.t("phrases.actions")} />,
      content: <ImpactActionSidebar projectId={this.projectId} impactId={this.impact?.id} impact={this.impact} />,
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };
}
