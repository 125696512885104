import { ButtonTypes, LinkButton } from "../../../../../../../../components/ui/Button";
import { UiSizes } from "../../../../../../../../enums";
import I18n from "../../../../../../../localization/I18n";

export const EmptyPhaseGridTemplate = (params?: any) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <span>{I18n.t("phrases.emptyPhasesGridData")}</span>
      {params.showLink && (
        <LinkButton className="m-2" size={UiSizes.SM} href={params.link ?? ""} type={ButtonTypes.LINK_BIG}>
          {I18n.t("phrases.goToPhasesLabel")}
        </LinkButton>
      )}
    </div>
  );
};
