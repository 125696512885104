import { ColDef } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { yesNoTextMatcher } from "../../../../filters/TextMatcher";
import {
  AuccaColDefFieldNamesEnum,
  AudienceColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum,
  StakeholderColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NoteCountColumnBuilder } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_config";
import { RefNumberColumnBuilder } from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_builder";
import { REF_NUMBER_COLUMN_CONFIG } from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_config";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { AuccaGridColumnBuilder } from "../base/AuccaGridColumnBuilder";
import { AudienceStakeholderProfilingField } from "../../utils/GridFields";
import PulseAudienceStakeholdersApi from "../../../../../../services/api/v2/pulseAudienceStakeholders/PulseAudienceStakeholders.api";

export interface PulseAudienceStakeholdersGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewStakeholders: boolean;
  columns: FP.Entities.IColumnDef[] | string[];
  onFieldUpdated: any;
}

export class PulseAudienceStakeholdersGridColumnBuilder extends AuccaGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: PulseAudienceStakeholdersGridColumnBuilderProps;
  columnDefs: Dictionary<(header?: string) => ColDef>;
  organisationId: number;
  projectId: number;
  onFieldUpdated: any;

  constructor(gridProps: PulseAudienceStakeholdersGridColumnBuilderProps) {
    super(PulseAudienceStakeholdersApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.onFieldUpdated = gridProps.onFieldUpdated;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Name]: header => this.buildNameColumn(header),
      [StakeholderColDefFieldNamesEnum.Email]: (header: string) =>
        new SimpleTextColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.Email,
          headerName: header || I18n.t("grids.email")
        })
          .makeReadOnly(!this.canEdit)
          .makeSelectable(false)
          .makeEditable(false)
          .setFilterOptions(BASE_FILTER_CONFIG)
          .generateColumnOptions(),
      [AuccaColDefFieldNamesEnum.Awareness]: (header: string) =>
        this.buildEditableAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Awareness,
          header || "Awareness",
          this.canEdit,
          AudienceStakeholderProfilingField.awareness,
          this.updateProfiling
        ),
      [AuccaColDefFieldNamesEnum.Understanding]: (header: string) =>
        this.buildEditableAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Understanding,
          header || "Understand",
          this.canEdit,
          AudienceStakeholderProfilingField.understanding,
          this.updateProfiling
        ),
      [AuccaColDefFieldNamesEnum.Commitment]: (header: string) =>
        this.buildEditableAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Commitment,
          header || "Commit",
          this.canEdit,
          AudienceStakeholderProfilingField.commitment,
          this.updateProfiling
        ),
      [AuccaColDefFieldNamesEnum.Capability]: (header: string) =>
        this.buildEditableAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Capability,
          header || "Acquire",
          this.canEdit,
          AudienceStakeholderProfilingField.capability,
          this.updateProfiling
        ),
      [AuccaColDefFieldNamesEnum.Adoption]: (header: string) =>
        this.buildEditableAudienceProfilingColumn(
          AuccaColDefFieldNamesEnum.Adoption,
          header || "Apply",
          this.canEdit,
          AudienceStakeholderProfilingField.adoption,
          this.updateProfiling
        ),
      [AudienceColDefFieldNamesEnum.IsKeyStakeholder]: (header: string) => this.buildIsStakeholderColumn(header)
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      if (e) {
        res.push(this.columnDefs[e]());
      }
    });

    return res;
  };

  buildNameColumn = (header: string) => {
    let model = new RefNumberColumnBuilder()
      .makeEditable(false)
      .makeSelectable(false)
      .makeReadOnly(true)
      .setColumnOptions({
        ...REF_NUMBER_COLUMN_CONFIG({
          field: CommonColDefFieldNamesEnum.Name,
          headerName: header || I18n.t("grids.name")
        })
      })
      .setFilterOptions(BASE_FILTER_CONFIG);

    return model.generateColumnOptions();
  };

  buildNoteCountColumn = (header: string) => {
    let model = new NoteCountColumnBuilder()
      .setColumnOptions({
        ...NOTE_COUNT_COLUMN_CONFIG(),
        field: StakeholderColDefFieldNamesEnum.NoteCount,
        headerName: header || "Note Count"
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit);

    return model.generateColumnOptions();
  };

  buildIsStakeholderColumn = (header: string) => {
    let model = new NameColumnBuilder()
      .makeEditable(false)
      .makeSelectable(false)
      .makeReadOnly(true)
      .setColumnOptions({
        field: AudienceColDefFieldNamesEnum.IsKeyStakeholder,
        headerName: header || I18n.t("grids.keyStakeholder"),
        cellRenderer: YesNoComponent
      })
      .setFilterOptions({
        filter: "agTextColumnFilter",
        filterParams: {
          textMatcher: yesNoTextMatcher
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable(true);
    }

    return model.generateColumnOptions();
  };

  //#region Update Field Methods

  updateProfiling = async (
    audienceStakeholderProfilingField: AudienceStakeholderProfilingField,
    entityId: number,
    value: number
  ) => {
    if ((value <= 0 || value > 5) && value !== null) {
      alert("Value should be more than 0 and less or equal to 5.");
      return;
    }
    await this.updateNumericField(audienceStakeholderProfilingField, entityId, value);
    this.onFieldUpdated();
  };
  //#endregion
}

const YesNoComponent = props => {
  let val = props.data[props.colDef.field];
  return val ? I18n.t("phrases.yes") : <span style={{ color: "#aaa" }}>{I18n.t("phrases.no")}</span>;
};
