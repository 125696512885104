import { observer } from "mobx-react";
import { ActionProgressStatusRagStatusChart } from "../ActionProgressStatusRagStatusChart/ActionProgressStatusRagStatusChart_view";
import { Panel } from "../../../../../../../components/ui/Panel";
import ProjectProcessesApi from "../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import I18n from "../../../../../../../core/localization/I18n";
import Pages from "../../../../../../../routes/InsightRoutes";
import {
  ActionColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum
} from "../../../../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";
import {
  MapProgressStatusesFromActionStackBarChart,
  MapRagStatusesFromActionStackBarChart
} from "../../../../Actions/ActionSummary/ActionSummary_mappers";
import { useState } from "react";
import { Button, ButtonTypes } from "../../../../../../../components/ui/Button";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { useNavigate } from "react-router-dom";
import { UrlOperators } from "../../../../../../../core/grids/builder/directorBuilder/grids/base/addons/FilterAddon/FilterAddonContants";

interface ActionChartsWrapperProps {
  organisationId: number;
  projectId: number;
  filterSelectedItemIds: number[];
}

export const ActionChartsWrapper: React.FC<ActionChartsWrapperProps> = observer(
  ({ organisationId, projectId, filterSelectedItemIds }) => {
    const [currentActionGraph, setCurrentActionGraph] = useState<string>(CommonColDefFieldNamesEnum.ProgressStatus);

    const navigate = useNavigate();
    const mappedProgressStatuses = MapProgressStatusesFromActionStackBarChart();
    const mappedRagStatuses = MapRagStatusesFromActionStackBarChart();

    return (
      <Panel.Panel
        hasBorder={false}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius
        className="processes-actions-chart p-3"
      >
        <div className="col d-flex justify-content-end">
          <Button
            size={UiSizes.SM}
            type={
              currentActionGraph === CommonColDefFieldNamesEnum.ProgressStatus
                ? ButtonTypes.PRIMARY
                : ButtonTypes.OUTLINE_DARK
            }
            className="mx-2"
            onClick={() => setCurrentActionGraph(CommonColDefFieldNamesEnum.ProgressStatus)}
            isDisabled={currentActionGraph === CommonColDefFieldNamesEnum.ProgressStatus}
          >
            {I18n.t("phrases.progressStatus")}
          </Button>
          <Button
            size={UiSizes.SM}
            type={
              currentActionGraph === ActionColDefFieldNamesEnum.RagStatus
                ? ButtonTypes.PRIMARY
                : ButtonTypes.OUTLINE_DARK
            }
            className="mx-2"
            onClick={() => setCurrentActionGraph(ActionColDefFieldNamesEnum.RagStatus)}
            isDisabled={currentActionGraph === ActionColDefFieldNamesEnum.RagStatus}
          >
            {I18n.t("phrases.ragStatus")}
          </Button>
        </div>
        {currentActionGraph === CommonColDefFieldNamesEnum.ProgressStatus && (
          <ActionProgressStatusRagStatusChart
            title={I18n.t("phrases.processActions")}
            loadDataFn={ProjectProcessesApi.getProjectProcessActionsProgressStatusReport}
            selectedItemIds={filterSelectedItemIds}
            transformer={data => ({
              data,
              tooltipLabelFn: item => {
                return `${I18n.t(`phrases.${item.id}`)} - ${item.indexValue}`;
              },
              onClick: item => {
                const url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?${
                  CommonColDefFieldNamesEnum.ProgressStatus
                }=${UrlOperators.contains.symbol}${mappedProgressStatuses.find(e => e.id === item.id).label}&${
                  CommonColDefFieldNamesEnum.Processes
                }=${UrlOperators.contains.symbol}${item.data.name}`;
                navigate(url);
              },
              bottomAxisValueFormat: (val: string) => ({
                value: val,
                maxLineLength: 18,
                maxLines: 1,
                truncateAtChar: 15
              }),
              indexBy: "name",
              keys: ["completed", "inProgress", "notStarted"],
              bottomAxisLabel: I18n.t("phrases.processes"),
              colors: ["#58C8D1", "#FFC036", "#E5E5E5"],
              legendLabel: e => {
                return I18n.t(`phrases.${e.id}`);
              },
              leftAxisLabel: I18n.t("phrases.numberOfActions")
            })}
          />
        )}

        {currentActionGraph === ActionColDefFieldNamesEnum.RagStatus && (
          <ActionProgressStatusRagStatusChart
            title={I18n.t("phrases.processActions")}
            loadDataFn={ProjectProcessesApi.getProjectProcessActionsRagStatusReport}
            selectedItemIds={filterSelectedItemIds}
            transformer={data => ({
              data,
              tooltipLabelFn: item => {
                return `${I18n.t(`phrases.${item.id}`)} - ${item.indexValue}`;
              },
              bottomAxisValueFormat: (val: string) => ({
                value: val,
                maxLineLength: 18,
                maxLines: 1,
                truncateAtChar: 15
              }),
              onClick: item => {
                const url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?${
                  item.id === "completed"
                    ? CommonColDefFieldNamesEnum.ProgressStatus
                    : ActionColDefFieldNamesEnum.RagStatus
                }=${UrlOperators.contains.symbol}${mappedRagStatuses.find(e => e.id === item.id).label}&${
                  CommonColDefFieldNamesEnum.Processes
                }=${UrlOperators.contains.symbol}${item.data.name}`;
                navigate(url);
              },
              indexBy: "name",
              bottomAxisLabel: I18n.t("phrases.processes"),
              keys: ["completed", "red", "amber", "green", "unknown"],
              colors: ["#58C8D1", "#F91E1E", "#F9AB1E", "#1AC541", "#E5E5E5"],
              legendLabel: e => {
                return I18n.t(`phrases.${e.id}`);
              },
              leftAxisLabel: I18n.t("phrases.numberOfActions")
            })}
          />
        )}
      </Panel.Panel>
    );
  }
);
