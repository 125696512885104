import { action, makeObservable, observable } from "mobx";
import AudiencesApi, {
  AudiencesApi as IAudiencesApi
} from "../../../../../../../../services/api/v2/audiences/Audiences.api";

export class PulseAudienceStakeholdersGridModel {
  organisationId: number;
  projectId: number;
  pulseId: number;
  audienceId: number;
  @observable isLoading: boolean = true;
  @observable stakeholders: FP.Entities.IStakeholder[];
  audiencesProvider: IAudiencesApi;
  onFieldUpdate: any;

  constructor(organisationId: number, projectId: number, pulseId: number, audienceId: number, onFieldUpdate: any) {
    makeObservable(this);
    this.audiencesProvider = AudiencesApi;
    this.organisationId = organisationId;
    this.pulseId = pulseId;
    this.projectId = projectId;
    this.audienceId = audienceId;
    this.onFieldUpdate = onFieldUpdate;
  }

  onMount = async () => {
    await this.loadData();
  };

  loadData = async () => {
    const res = await this.audiencesProvider.getPulseStakeholders(
      this.organisationId,
      this.projectId,
      this.pulseId,
      this.audienceId
    );

    if (!res.error && res.payload) {
      this.setStakeholders(res.payload);
    }

    this.setIsLoading(false);
  };

  handleFieldUpdate = async () => {
    this.setIsLoading(true);
    await this.loadData();
    await this.onFieldUpdate();
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setStakeholders = (stakeholders: FP.Entities.IStakeholder[]) => {
    this.stakeholders = stakeholders;
  };
}
