import { action, makeObservable, observable } from "mobx";
import ProcessesApi from "../../../../../services/api/v2/processes/Processes.api";
import { buildTree } from "../../../../../core/util/Helpers";
import ProcessRolesApi from "../../../../../services/api/v2/processRoles/ProcessRoles.api";

export class ProcessesSettingsModel {
  organisationId: number;
  @observable processData: any[] = [];
  @observable processRoleData: any[] = [];
  @observable treeData: any;
  @observable isLoading: boolean = true;
  processProvider = ProcessesApi;
  processRoleProvider = ProcessRolesApi;

  /**
   *
   */
  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
  }

  onMount = () => {
    this.loadProcessData();
    this.loadProcessRoleData();
  };

  loadProcessData = async () => {
    let res = await this.processProvider.getAll(this.organisationId, { params: { forceTopProgressBar: true } });

    if (res.isError) {
      return;
    }

    this.setProcessData(res.payload);
  };

  loadProcessRoleData = async () => {
    let res = await this.processRoleProvider.getAll(this.organisationId);

    if (res.isError) {
      return;
    }

    this.setProcessRoles(res.payload);
  };

  @action
  setProcessData = (processData: any[]) => {
    const mappedResults = processData.map(e => {
      return {
        ...e,
        parent: e.parent === 0 || !e.parent ? "" : e.parent
      };
    });
    this.treeData = buildTree(mappedResults);

    this.processData = buildProcessListData(this.treeData);
    this.setIsLoading(false);
  };

  @action
  setProcessRoles = (processRoles: any[]) => (this.processRoleData = processRoles);

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}

export const buildProcessListData = (data: any[]) => {
  let res = [];

  data.forEach(l1 => {
    res.push({
      level1: l1.name,
      id: l1.id,
      level: l1.level,
      organisationId: l1.organisationId,
      parent: l1.parent,
      uniqueIdentifier: l1.uniqueIdentifier
    });

    l1?.children?.forEach(l2 => {
      res.push({
        id: l2.id,
        level1: l1.name,
        level2: l2.name,
        level: l2.level,
        organisationId: l2.organisationId,
        parent: l2.parent,
        uniqueIdentifier: l2.uniqueIdentifier
      });

      l2?.children?.forEach(l3 => {
        res.push({
          id: l3.id,
          level1: l1.name,
          level2: l2.name,
          level3: l3.name,
          level: l3.level,
          organisationId: l3.organisationId,
          parent: l3.parent,
          uniqueIdentifier: l3.uniqueIdentifier
        });

        l3?.children?.forEach(l4 => {
          res.push({
            id: l4.id,
            level1: l1.name,
            level2: l2.name,
            level3: l3.name,
            level4: l4.name,
            level: l4.level,
            organisationId: l4.organisationId,
            parent: l4.parent,
            uniqueIdentifier: l4.uniqueIdentifier
          });

          l4?.children?.forEach(l5 => {
            res.push({
              id: l5.id,
              level1: l1.name,
              level2: l2.name,
              level3: l3.name,
              level4: l4.name,
              level5: l5.name,
              level: l5.level,
              organisationId: l5.organisationId,
              parent: l5.parent,
              uniqueIdentifier: l5.uniqueIdentifier
            });
          });
        });
      });
    });
  });

  return res;
};
