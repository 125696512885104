import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { AudiencesGridSidebarModel } from "./AudiencesGridSidebar_model";
import { AudiencesGrid } from "../../../../grids/builder/directorBuilder/grids/AudiencesGrid/AudiencesGridView_view";
import { GridTypes } from "../../../../../enums";

export const AudiencesGridSidebar: React.FC<{
  onAssignSuccess: (itemIds: number[]) => any;
  selectedItemIds: number[];
}> = observer(({ selectedItemIds, onAssignSuccess }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new AudiencesGridSidebarModel(organisationId, +projectId, selectedItemIds));

  useEffect(() => {
    model.onMount();

    //eslint-disable-next-line
  }, []);
  if (model.isLoading) return <PositionedSpinner />;
  return (
    <div className="audiences-modal-view insight-tab-content__space-filler" style={{ height: "100%" }}>
      <AudiencesGrid
        data={model.data}
        gridToolbarType="link-modal"
        isLoading={model.isLoading}
        preSelectedItemIds={selectedItemIds}
        onAssignSuccess={onAssignSuccess}
        columnDefs={[]}
        gridType={GridTypes.AUDIENCE_MODAL_GRID}
      />
    </div>
  );
});
