import { observer } from "mobx-react-lite";
import { ReadinessLineChart } from "../../../../../core/charts/ReadinessLineChart/ReadinessLineChart_view";
import { ReadinessChartsViewModel } from "./ReadinessChartsView_model";
import { useEffect, useRef, useState } from "react";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { ReportFilterView } from "../../../../../core/reportFilter/ReportFilter_view";
import { ReportFilterAddonsKeys } from "../../../../../core/reportFilter/ReportFilterAddon";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import I18n from "../../../../../core/localization/I18n";
import Pages from "../../../../../routes/InsightRoutes";
import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { UiSizes } from "../../../../../enums";
import moment from "moment";

export const ReadinessChartsView: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const readinessLineChartRef = useRef();
  const [model] = useState(() => new ReadinessChartsViewModel(organisationId, +projectId));
  const [canShowChart, setCanShowChart] = useState<boolean>(false);

  useEffect(() => {
    model.onMount().then(() => {
      setCanShowChart((): boolean => {
        const projectStartDate = moment(model.project?.startDate, "DD/MM/YYYY");
        return (
          model.phases?.length > 0 &&
          model.phases?.filter(p => moment(p?.startDate, "DD/MM/YYYY") >= projectStartDate && !!p?.startDate)?.length >
            0
        );
      });
    });
    //eslint-disable-next-line
  }, []);

  if (model.isLoading) return <PositionedSpinner />;
  return (
    <div className="container-fluid">
      <div className=" d-flex flex-row p-3">
        <h1 className="w-100 m-0">{I18n.t("phrases.audienceReadinessChartsHeader")}</h1>
        <ReportFilterView
          addons={[ReportFilterAddonsKeys.AudienceGrid]}
          filterState={model.filterJSON}
          onClearClicked={model.resetLocalStorage}
          onFilterChange={async k => {
            model.setFilterJSONFromList(k);

            return await Promise.all([model.loadData()]).then(async e => {
              return null;
            });
          }}
        />
      </div>
      {!!canShowChart ? (
        <ReadinessLineChart
          ref={readinessLineChartRef}
          data={model.auccaScores}
          groups={model.phases}
          dateRange={model.project}
          groupMembers={model.pulses}
          xAxisLabel={I18n.t("entities.pulses")}
          yAxisLabel={I18n.t("phrases.readinessScore")}
        />
      ) : (
        <div className="pt-8">
          <MissingOrEmptyDataTemplate
            model={model}
            link={Pages.projects.settings.phases.generateLink(organisationId, projectId)}
          />
        </div>
      )}
    </div>
  );
});

const MissingOrEmptyDataTemplate = observer((params: any, model: ReadinessChartsViewModel) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      {!(model.phases?.length > 0 && model.pulses?.length > 0) ? (
        <span>{I18n.t("phrases.emptyOrMissingReadinessChartData")}</span>
      ) : !(model.phases?.length > 0) ? (
        <span>{I18n.t("phrases.emptyOrMissingPhaseData")}</span>
      ) : (
        !(model.pulses?.length > 0) && <span>{I18n.t("phrases.emptyOrMissingPulseData")}</span>
      )}
      {!(model.pulses?.length > 0 || model.phases?.length > 0) && (
        <span>{I18n.t("phrases.createOneWIthStartDateMessage")}</span>
      )}
      <LinkButton className="m-2" size={UiSizes.SM} href={params.link ?? ""} type={ButtonTypes.LINK_BIG}>
        {I18n.t("phrases.goToPhasesLabel")}
      </LinkButton>
    </div>
  );
});
