import { ColDef } from "ag-grid-community";
import {
  colEndDate,
  colName,
  colOwner,
  colProgrammeName,
  colProgressStatus,
  colProjectName,
  colRefNumber,
  colStartDate
} from "../../../../../core/grids/CommonGridColumns";
import { ActionField, EntityTypes, Enums, ImpactField, Translator } from "../../../../../enums";
import Pages from "../../../../../routes/InsightRoutes";
import { baseColumn } from "../../../../../core/grids/BaseColumns";
import AgGridAutocomplete from "../../../../../core/grids/editors/AgGridAutocomplete";
import { zeroToTenTextMatcher } from "../../../../../core/grids/filters/TextMatcher";
import { makeCellDeletable } from "../../../../../core/grids/GridHelpers";
import I18n from "../../../../../core/localization/I18n";

export interface IGetImpactGridViewColumns {
  progressStatuses: FP.Generic.IKeyLabel[];
  projectTeamMembers: FP.Entities.IUser[];
  canEdit: boolean;
  organisationId: number;
  projectId: number;
}

export const GetImpactGridViewColumns = (modelProps: IGetImpactGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];

  result.push(
    colRefNumber(modelProps.organisationId, modelProps.projectId, EntityTypes.IMPACTS, { pinned: undefined })
  );
  result.push(colName(modelProps.canEdit, null, ActionField.name, "", { width: 200, pinned: undefined }));
  result.push(colImpactLevel(modelProps.canEdit));

  result.push(
    colProjectName(
      modelProps.canEdit,
      null,
      ActionField.projectName,
      Pages.baseRoute.generateLink(modelProps.organisationId),
      { width: 200, pinned: undefined }
    )
  );
  result.push(
    colProgrammeName(
      modelProps.canEdit,
      null,
      ActionField.programmeName,
      Pages.baseRoute.generateLink(modelProps.organisationId),
      { width: 200, pinned: undefined }
    )
  );

  result.push(colOwner(modelProps.canEdit, modelProps.projectTeamMembers, null, ActionField.owner, {}));
  result.push(colStartDate(modelProps.canEdit, null, ActionField.startDate, {}));
  result.push(colEndDate(modelProps.canEdit, null, ActionField.endDate, {}));
  result.push(colProgressStatus(modelProps.canEdit, modelProps.progressStatuses, null, ActionField.progressStatus, {}));

  return result;
};

const colImpactLevel = (canEdit: boolean, impactLevels?: FP.Generic.IKeyLabel[], saveFn?: any): ColDef => {
  let result: ColDef = {
    ...baseColumn({
      fieldName: "impactLevel",
      heading: I18n.t("grids.impactLevel"),
      width: 150,
      editable: canEdit,
      clickEditable: true
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, popupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;
      let newVal = Enums.Translator.ImpactLevelShortToLong(+params.newValue.key);
      saveFn(ImpactField.impactLevel, params.node.id, newVal);
      return true;
    },
    cellDataType: "any",
    filter: "agTextColumnFilter",
    filterParams: {
      textMatcher: zeroToTenTextMatcher
    },
    cellEditorParams: {
      field: "impactLevel",
      getValueLabel: (ee: number) => {
        const shortVal = Enums.Translator.ImpactLevelLongToShort(ee);
        return shortVal > 0 ? impactLevels.find(e => e.key === shortVal.toString()).label : "";
      },
      options: impactLevels ?? []
    },
    getQuickFilterText: params => {
      if (params.value === -1) return "";

      return Translator.ImpactLevel(params.value);
    }
  };
  result.type = makeCellDeletable(result.type);
  result.cellRenderer = params => {
    const impact: FP.Entities.IImpactSummary = params.data;
    return impact.impactLevel > 0 ? <span>{Enums.Translator.ImpactLevel(impact.impactLevel)}</span> : null;
  };

  return result;
};
