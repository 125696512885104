import { observer } from "mobx-react";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import { Input } from "../../../../../../components/ui/_forms/Input";
import { Enums, UiSizes } from "../../../../../../enums";
import { Button, ButtonIcon, ButtonIconShapes, ButtonTypes } from "../../../../../../components/ui/Button";
import ModalContext from "../../../../../modalZ/context/ModalContext";

interface ModalGridToolbarProps {
  className?: string;
  onQuichSearchInputChange: (ev) => void;
  onAssignSuccess: any;
  selectedItems?: number[];
  preSelectedItemIds?: number[];
}

export const ModalGridToolbar: React.FC<ModalGridToolbarProps> = observer(
  ({ className, onQuichSearchInputChange, onAssignSuccess, selectedItems, preSelectedItemIds }) => {
    return (
      <div className={"modal-grid-toolbar " + className || ""}>
        <div className="container-fluid py-3">
          <div className="row">
            <div className="col-10">
              {!!onAssignSuccess && (
                <Button
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  size={UiSizes.SM}
                  isDisabled={selectedItems?.length === 0 && typeof preSelectedItemIds === "undefined"}
                  className="mt-2 mr-2 align-self-start"
                  onClick={async () => {
                    onAssignSuccess(selectedItems);
                  }}
                >
                  Assign
                </Button>
              )}

              <Input
                autoFocus={true}
                className="mt-2"
                size={UiSizes.XS}
                onChange={onQuichSearchInputChange}
                placeholder="Search"
                icon={IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                iconClass="mt-1"
                style={{ height: 32 }}
              />
            </div>
            <div className="col-2 d-flex justify-content-end align-items-end px-0">
              <ButtonIcon
                size={UiSizes.SM}
                onClick={ModalContext.hide}
                type={ButtonTypes.OUTLINE_PRIMARY}
                shape={ButtonIconShapes.RECTANGLE}
                symbol={IconSymbols.Close}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
