import { useEffect, useState } from "react";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import PhaseDetailCellRendererModel from "./PhaseDetailCellRenderer_model";
import { PulsesGrid } from "../../PulsesGrid/PulsesGridView_view";
import { GridApi, IRowNode } from "ag-grid-community";

export interface IPhaseDetailCellRendererProps {
  data: any;
  node: IRowNode;
  api: GridApi;
  showAudiences: boolean;
  masterRefreshDataHandler: any;
}

const PhaseDetailCellRenderer: React.FunctionComponent<IPhaseDetailCellRendererProps> = observer(props => {
  const organisationId = useCurrentOrganisationId();
  const { data, node, api, showAudiences } = props;
  const { projectId } = useParams();
  const rowId = node.id!;
  const { selectedDetail } = api.getGridOption("context");

  const [model, setModel] = useState(
    () => new PhaseDetailCellRendererModel(organisationId, +projectId, data.id, props)
  );

  useEffect(() => {
    if (
      (typeof projectId !== "undefined" && model.projectId !== +projectId) ||
      model.organisationId !== organisationId
    ) {
      setModel(new PhaseDetailCellRendererModel(organisationId, +projectId, data.id, props));
    }
    // eslint-disable-next-line
  }, [organisationId, projectId]);

  useEffect(() => {
    if (selectedDetail.some(e => e.rowId === data.id)) {
      model.loadPulses();
    }
    return () => {
      if (!api.isDestroyed()) {
        api.removeDetailGridInfo(rowId);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="container-fluid pulse-detail-row-content"
      style={{
        height: `${selectedDetail.some(e => e.rowId === data.id) ? "600px" : "auto"}`
      }}
    >
      {selectedDetail.some(e => e.rowId === data.id) && (
        <div className="col p-0 phase-pulses insight-tab-content__space-filler" style={{ height: "100%" }}>
          <PulsesGrid
            phaseId={data.id}
            data={model.pulses}
            refreshDataHandler={model.loadPulses}
            onFieldUpdate={model.loadPulses}
            showAudiences={showAudiences}
            masterRefreshDataHandler={props.masterRefreshDataHandler}
          ></PulsesGrid>
        </div>
      )}
    </div>
  );
});

export default PhaseDetailCellRenderer;
