import { ButtonTypes } from "../../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import I18n from "../../../../../localization/I18n";
import { UiActionRenderers } from "../../../../../uiAction/IUiAction";
import { GridActionEnum } from "../../../enums/GridActionEnum";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { PulsesGridModel } from "./PulsesGridView_model";

export const GetPulsesGridActions = (model: PulsesGridModel): IGridUiAction[] => {
  var actions = [];

  if (!model.showAudiences) {
    actions = [
      ...actions,
      {
        id: "name",
        label: I18n.t("phrases.add"),
        componentProps: {
          symbol: IconSymbols.Plus
        },
        type: "microForm",
        contextProps: {
          formModel: model.microPulseForm,
          onSubmit: model.createMicroPulse
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      },
      {
        id: GridActionEnum.delete,
        label: I18n.t("phrases.delete"),
        type: "uiAction",
        onAction: ev => model.showPulseConfirmDeleteModal(),
        componentProps: {
          type: ButtonTypes.LINK,
          symbol: IconSymbols.Trash
        },
        disabledWhenNoItemSelected: true,
        rendersIn: UiActionRenderers.BUTTON_ICON
      },
      {
        id: "devider",
        label: "",
        type: "devider",
        onAction: ev => {},
        rendersIn: UiActionRenderers.BUTTON_ICON
      }
    ];
  }

  actions = [
    ...actions,
    {
      id: GridActionEnum.textWrap,
      label: I18n.t("phrases.textWrap"),
      type: "uiAction",
      onAction: ev => {
        model.textWrapAddon?.toggleTextWrap();
        model.generateActions();
      },
      componentProps: {
        type: model.textWrapAddon?.isWrappedText ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY,
        symbol: IconSymbols.TextWrap
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.clearFilters,
      label: I18n.t("phrases.clearFilters"),
      type: "uiAction",
      disabledWhenNoItemSelected: false,
      onAction: ev => {
        model.filterStoreAddon.clearFilters();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.ClearFilter,
        isDisabled: !model.isFilterChanged
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    },
    {
      id: GridActionEnum.resetColumns,
      label: I18n.t("phrases.resetColumns"),
      type: "uiAction",
      disabledWhenNoItemSelected: false,
      onAction: ev => {
        model.columnStateAddon.resetColumns();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        symbol: IconSymbols.MonitorBack,
        isDisabled: !model.isColumnStateChanged
      },
      rendersIn: UiActionRenderers.BUTTON_ICON
    }
  ];

  return actions;
};
