import { ColDef, ICellRendererParams } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { AudienceField, Enums, Translator } from "../../../../../../enums";
import { AudiencesApi } from "../../../../../../services/api/v2/audiences/Audiences.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { zeroToTenTextMatcher } from "../../../../filters/TextMatcher";
import {
  AuccaColDefFieldNamesEnum,
  AudienceColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { AuccaGridColumnBuilder } from "../base/AuccaGridColumnBuilder";
import { PULSE_AUDIENCE_STAKEHOLDERS_MODAL_CONFIG } from "./modals/audienceStakeholdersModal/AudienceStakeholdersModal_config";

export interface PulseAudiencesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  pulseId: number;
  userCanViewAudiences: boolean;
  columns: FP.Entities.IColumnDef[] | string[];
  audiencesProvider: AudiencesApi;
  onFieldUpdate: any;
}

export class PulseAudiencesGridColumnBuilder extends AuccaGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: PulseAudiencesGridColumnBuilderProps;
  columnDefs: Dictionary<(header?: string) => ColDef>;
  organisationId: number;
  projectId: number;
  pulseId: number;
  levelClassRules: any;
  onFieldUpdate: any;

  constructor(gridProps: PulseAudiencesGridColumnBuilderProps) {
    super(() => {}, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.levelClassRules = {
      "stakeholder-grid__cell--unknown": params =>
        params.value <= 0 || typeof params.value === "undefined" || params.value === null,
      "stakeholder-grid__cell--low": params => params.value >= 1 && params.value <= 4,
      "stakeholder-grid__cell--medium": params => params.value >= 5 && params.value <= 7,
      "stakeholder-grid__cell--high": params => params.value > 7
    };
    this.pulseId = gridProps.pulseId;
    this.onFieldUpdate = gridProps.onFieldUpdate;

    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Name]: (header?: string) => this.buildNameColumn(header),
      [AudienceColDefFieldNamesEnum.Impact]: (header?: string) =>
        this.buildProfilingColumn(
          AudienceColDefFieldNamesEnum.Impact,
          AudienceField.impact,
          `${header || I18n.t("grids.impact")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.ImpactLevelMapped(),
          zeroToTenTextMatcher
        )
          .makeEditable(false)
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            getQuickFilterText: params => {
              if (!params.data.influence) return "Unknown";

              return Enums.Translator.ImpactLevel(params.value);
            },
            valueFormatter: params => {
              const impact = Translator.ImpactLevelMapped().find(e => e.key === params.value + "");
              return impact ? impact.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Size]: (header?: string) =>
        this.buildProfilingColumn(
          AudienceColDefFieldNamesEnum.Size,
          AudienceField.size,
          `${header || I18n.t("grids.size")}`,
          this.gridProps.canEdit,
          Translator.SizeMapped(),
          zeroToTenTextMatcher
        )
          .makeEditable(false)
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            getQuickFilterText: params => {
              if (!params.data.influence) return "Unknown";

              return Enums.Translator.Size(params.value);
            },
            valueFormatter: params => {
              const impact = Translator.SizeMapped().find(e => e.key === params.value + "");
              return impact ? impact.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Influence]: (header?: string) =>
        this.buildProfilingColumn(
          AudienceColDefFieldNamesEnum.Influence,
          AudienceField.influence,
          `${header || I18n.t("grids.influence")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.InfluenceLevelMapped(),
          zeroToTenTextMatcher
        )
          .makeEditable(false)
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            valueFormatter: params => {
              const influence = Translator.InfluenceLevelMapped().find(e => e.key === params.value + "");
              return influence ? influence.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Count]: (header?: string) =>
        new NameColumnBuilder({
          field: AudienceColDefFieldNamesEnum.Count,
          headerName: header || I18n.t("grids.count"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [AuccaColDefFieldNamesEnum.Awareness]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Awareness, header || "Awareness"),
      [AuccaColDefFieldNamesEnum.Understanding]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Understanding, header || "Understand"),
      [AuccaColDefFieldNamesEnum.Commitment]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Commitment, header || "Commit"),
      [AuccaColDefFieldNamesEnum.Capability]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Capability, header || "Acquire"),
      [AuccaColDefFieldNamesEnum.Adoption]: (header?: string) =>
        this.buildAudienceProfilingColumn(AuccaColDefFieldNamesEnum.Adoption, header || "Apply")
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      if (e) {
        res.push(this.columnDefs[e]());
      }
    });

    return res;
  };
  buildNameColumn = (header?: string) => {
    let model = new NameColumnBuilder()
      .setColumnOptions(
        NAME_COLUMN_CONFIG({
          headerName: header || "Name",
          cellRenderer: (params: ICellRendererParams) => {
            return (
              <>
                <span onClick={ev => this.onAudienceExpand(params.data)}>
                  <span className="ag-icon ag-icon-tree-closed mr-2" unselectable="on" role="presentation"></span>
                </span>
                <span>{params.value}</span>
              </>
            );
          }
        })
      )
      .makeSelectable(false)
      .makeEditable(false)
      .setFilterOptions(NAME_FILTER_CONFIG);

    return model.generateColumnOptions();
  };

  onAudienceExpand = (audience: FP.Entities.IAudience) => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(
        PULSE_AUDIENCE_STAKEHOLDERS_MODAL_CONFIG(
          this.organisationId,
          this.projectId,
          this.pulseId,
          audience,
          this.onFieldUpdate
        )
      )
      .generateModal();
  };
}
