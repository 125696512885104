import { PulseAudienceStakeholdersGridModal } from "./AudienceStakeholdersModal_view";

export const PULSE_AUDIENCE_STAKEHOLDERS_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  pulseId: number,
  audience: FP.Entities.IAudience,
  onFieldUpdate: any
) => {
  return {
    showClose: false,
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    title: null,
    content: (
      <PulseAudienceStakeholdersGridModal
        projectId={projectId}
        audienceId={audience.id}
        pulseId={pulseId}
        organisationId={organisationId}
        onFieldUpdate={onFieldUpdate}
      />
    )
  };
};
