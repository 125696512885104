import { FormRequiredFieldIndicator } from "../../../../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator";
import { ErrorMessage } from "../../../../../../../components/ui/ErrorMessage";
import { FORM_COL } from "../../../../../../../constants";
import { ITextFieldModel } from "../../../../../../forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../../forms/controls/textField/TextField_init";
import { IFormFieldModel } from "../../../../../../forms/formField/IFormField";
import { Validations } from "../../../../../../forms/helpers/Validations";
import I18n from "../../../../../../localization/I18n";
import { IRTEditorModel } from "../../../../../../forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../../../forms/controls/rteditor/RTEditor_model";
import { generateFormFieldsFromJson } from "../../../../../../forms/helpers/FormFieldMappers";

export const getCreatePulseFormFields = (): IFormFieldModel<any, any>[] => {
  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: "Enter Pulse title",
    label: (
      <label htmlFor="name">
        {I18n.t("forms.pulseName")} <FormRequiredFieldIndicator />
      </label>
    ),
    value: "",
    fieldClassName: FORM_COL.HALF_WIDTH,
    validate: function () {
      let self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.setErrorMessage(<ErrorMessage>{I18n.t("validations.pulseName")}</ErrorMessage>);
        res = false;
      }
      return res;
    }
  };

  const description: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.overview")}</label>,
    placeholder: I18n.t("placeholders.overview"),
    fieldClassName: FORM_COL.HALF_WIDTH,
    value: "",
    hintLabel: <p className="mb-0 text-muted">{I18n.t("forms.pulseOverviewHint")}</p>,
    extractValue: function () {
      return this.value;
    }
  };

  const fields = [];

  fields.push(name);
  fields.push(description);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
