import { useEffect, useState } from "react";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import PulseDetailCellRendererModel from "./PulseDetailCellRenderer_model";
import { GridApi, IRowNode } from "ag-grid-community";
import { PulseAudiencesGrid } from "../../PulseAudiencesGrid/PulseAudiencesGridView_view";

export interface IPulseDetailCellRendererProps {
  data: any;
  node: IRowNode;
  api: GridApi;
  refreshParentDataHandler: any;
}

const PulseDetailCellRenderer: React.FunctionComponent<IPulseDetailCellRendererProps> = observer(props => {
  const organisationId = useCurrentOrganisationId();
  const { data, node, api, refreshParentDataHandler } = props;
  const { projectId } = useParams();
  const rowId = node.id!;
  const { selectedDetail } = api.getGridOption("context");

  const [model, setModel] = useState(
    () => new PulseDetailCellRendererModel(organisationId, +projectId, data.id, props, refreshParentDataHandler)
  );

  useEffect(() => {
    if (
      (typeof projectId !== "undefined" && model.projectId !== +projectId) ||
      model.organisationId !== organisationId
    ) {
      setModel(new PulseDetailCellRendererModel(organisationId, +projectId, data.id, props, refreshParentDataHandler));
    }
    // eslint-disable-next-line
  }, [organisationId, projectId]);

  useEffect(() => {
    if (selectedDetail?.some(e => e.rowId === data.id)) {
      model.loadAudiences();
    }
    return () => {
      if (!api.isDestroyed()) {
        api.removeDetailGridInfo(rowId);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="container-fluid pulse-audience-detail-row-content"
      style={{
        height: `600px`
      }}
    >
      <div className="col p-0 pulse-audiences insight-tab-content__space-filler" style={{ height: "100%" }}>
        <PulseAudiencesGrid
          refreshDataHandler={model.refreshData}
          pulseId={data.id}
          data={model.audiences}
          isLoading={model.isLoading}
        ></PulseAudiencesGrid>
      </div>
    </div>
  );
});

export default PulseDetailCellRenderer;
