import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { AudienceMapModel } from "./AudienceMap_model";
import { AudienceHeatmap } from "./components/AudienceHeatmap/AudienceHeatmap_view";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { Panel } from "../../../../../components/ui/Panel";

export const AudienceMap: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId, section } = useParams();
  const [model] = useState(() => new AudienceMapModel(organisationId, +projectId));

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="audience-map container-fluid my-5">
      <div className="row">
        <div className="col">
          <Panel.Panel className="p-3" hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT}>
            <AudienceHeatmap data={model.audiences} profilingType={(section as any) || "0"} />
          </Panel.Panel>
        </div>
      </div>
    </div>
  );
});
