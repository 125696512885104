import { ColDef } from "ag-grid-community";
import _ from "lodash";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import ProcessRolesApi from "../../../../../../services/api/v2/processRoles/ProcessRoles.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { CommonColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { ProcessRoleField } from "../../utils/GridFields";
import {} from "../ImpactGroupsGrid/modals/noteCountModal/ImpactGroupNotesSidebar";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";

export interface ProcessRolesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  columns: any[];
  onFieldUpdate: any;
}

export class ProcessRolesGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: ProcessRolesGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;

  constructor(gridProps: ProcessRolesGridColumnBuilderProps) {
    super(ProcessRolesApi.updateField, gridProps.organisationId, null, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;

    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),

      // [CommonColDefFieldNamesEnum.CreatedBy]: () =>
      //   new NameColumnBuilder({
      //     field: CommonColDefFieldNamesEnum.CreatedBy,
      //     headerName: I18n.t("grids.createdBy"),
      //     pinned: false
      //   })
      //     .makeEditable(false)
      //     .makeReadOnly()
      //     .setFilterOptions(NAME_FILTER_CONFIG)
      //     .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      // [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
      //   new NameColumnBuilder({
      //     field: CommonColDefFieldNamesEnum.ModifiedBy,
      //     headerName: I18n.t("grids.lastModifiedBy"),
      //     pinned: false
      //   })
      //     .makeEditable(false)
      //     .makeReadOnly()
      //     .setFilterOptions(NAME_FILTER_CONFIG)
      //     .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    return _.map(this.columnDefs, e => {
      return e();
    });
  };

  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(ProcessRoleField.NAME, entityId, text);
    this.gridProps.onFieldUpdate();
  };
  //#endregion
}
