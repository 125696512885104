import * as _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { ButtonTypes } from "../../../../components/ui/Button";
import { Panel } from "../../../../components/ui/Panel";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { Animations } from "../../../../core/util/Animations";
import { BaseModel } from "../../../../core/util/BaseModel";
import { Enums, StakeholderType } from "../../../../enums";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../services/api/v2/organisations/Organisations.api";
import { ImpactStakeholdersSidebar } from "./impactStakeholderSidebar/impactStakeholdersSidebar_view";
import { ImpactStakeholdersSidebarModel } from "./impactStakeholderSidebar/ImpactStakeholdersSidebar_model";

export class ImpactStakeholdersModel extends BaseModel {
  impactProvider: IImpactsApi;
  isRouteView: boolean = true;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  impactId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable currentView: "individual" | "groups" = "individual";
  @observable.ref impact: FP.Entities.IImpact;
  @observable.ref projectStakeholders: FP.Entities.IProjectStakeholder[];
  @observable.ref filteredProjectStakeholders: FP.Entities.IProjectStakeholder[];
  @observable.ref stakeholderGroups: Dictionary<FP.Entities.IProjectStakeholder[]>;
  organisationProvider: IOrganisationsApi;
  organisationId: number;

  constructor(projectId: number, impactId: number, organisationId: number, isRouteView: boolean = true) {
    super();
    makeObservable(this);
    this.impactProvider = ImpactsApi;
    this.modalService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.organisationProvider = OrganisationsApi;
    this.organisationId = organisationId;
    this.isRouteView = isRouteView;
    this.projectId = projectId;
    this.impactId = impactId;
  }

  onMount = () => {
    this.loadImpact(this.impactId);
    this.loadProjectStakeholders(this.impactId);
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  loadImpact = async (impactId: number) => {
    this.setIsLoading(true);
    let res = await this.impactProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setImpact(res.payload);
  };

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
    this.stakeholderGroups = this.getStakeholderGroups(this.projectStakeholders);
    this.setIsLoading(false);
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterProjectStakeholders();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterProjectStakeholders();
    this.hideSearchMode();
  };

  @action
  removeProjectStakeholder = async (stakeholderId: number) => {
    this.setIsLoading(true);
    let res = await this.impactProvider.removeStakeholder(
      this.organisationId,
      this.projectId,
      this.impactId,
      stakeholderId
    );
    if (!res || res.isError) return;
    this.loadProjectStakeholders(this.impactId);
    this.setIsLoading(false);
  };

  @action
  loadProjectStakeholders = async (impactId: number) => {
    this.setIsLoading(true);
    let res = await this.impactProvider.getStakeholders(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;

    this.setProjectStakeholders(res.payload);
    this.filterProjectStakeholders();

    this.setIsLoading(false);
  };

  @action
  setProjectStakeholders = (projectstakeholders: FP.Entities.IProjectStakeholder[]) => {
    this.projectStakeholders = projectstakeholders;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  filterProjectStakeholders = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredProjectStakeholders = this.projectStakeholders.filter(pStakeholder => {
        const name =
          pStakeholder.stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
            ? `${pStakeholder.stakeholder.firstName} ${pStakeholder.stakeholder.lastName}`
            : pStakeholder.stakeholder.firstName;
        const lowerName = name.toLowerCase();

        return lowerName.includes(lowerSearch);
      });
    } else {
      this.filteredProjectStakeholders = this.projectStakeholders;
    }
  };

  getStakeholderGroups = (
    stakeholders: FP.Entities.IProjectStakeholder[]
  ): Dictionary<FP.Entities.IProjectStakeholder[]> => {
    let res = _.groupBy(stakeholders, e => {
      return e.stakeholderGroup ? e.stakeholderGroup.slug : "other";
    });
    return res;
  };

  @action
  showAddStakeholdersModal = () => {
    this.modalService.show({
      showClose: true,
      title: <h1 className="mt-6">{I18n.t("phrases.addStakeholder")}</h1>,
      content: (
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col">
              <p>{I18n.t("phrases.addImpactStakeholderDescription")}</p>
            </div>
          </div>
          <ImpactStakeholdersSidebar
            model={
              new ImpactStakeholdersSidebarModel(
                this.modalService.hide,
                this.organisationId,
                this.projectId,
                this.impact.id,
                StakeholderType.INDIVIDUAL
              )
            }
          />
        </div>
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
        speed: 5
      },
      actions: []
    });
  };

  setCurrentView = (currentView: "individual" | "groups") => {
    this.currentView = currentView;
  };

  removeStakeholder = async (impactId: number, stakeholderId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.impactProvider.removeStakeholder(this.organisationId, this.projectId, impactId, stakeholderId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    return res.payload;
  };

  showStakeholderConfirmDeleteModal = (impactId: number, stakeholder: FP.Entities.IStakeholder) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              {I18n.t("phrases.confirmRemove", { name: `${stakeholder.firstName} ${stakeholder.lastName}` })}
            </div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeStakeholder(impactId, stakeholder.id);
          this.loadImpact(impactId);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };
}
