import { ColDef, GridApi, IRowNode } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { ActionField, EntityTypes, Enums, GridTypes } from "../../../../../../enums";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import I18n from "../../../../../localization/I18n";
import { IModalContextModel } from "../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../../services/api/v2/actions/Actions.api";
import { GetActionsGridActions } from "./ActionsGrid_actions";
import { STAKEHOLDER_EMAILS_MODAL_CONFIG } from "./modals/stakeholderEmailsModal/StakeholderEmails_config";
import { ACTION_UPLOADER_MODAL_CONFIG } from "./modals/actionUploaderModal/ActionUploaderModal_config";
import { ACTION_BULK_EDIT_MODAL_CONFIG } from "./modals/actionBulkEditSidebar/ActionBulkEditSidebar_config";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../services/api/v2/impacts/Impacts.api";
import {
  ACTIONS_LINK_TO_AUDIENCES_MODAL_CONFIG,
  ACTIONS_LINK_TO_IMPACTS_MODAL_CONFIG,
  ACTIONS_LINK_TO_LABELS_MODAL_CONFIG,
  ACTIONS_LINK_TO_STAKEHOLDERS_MODAL_CONFIG,
  SHOW_ACTION_DELETE_CONFIRM_MODAL,
  SHOW_ACTION_REVIEW_CONFIRM_MODAL,
  SHOW_CONFIRM_CREATION_MODAL
} from "./ActionsGrid_modals";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { ActionsGridColumnBuilder } from "./ActionsGrid_columns";
import {
  ActionColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import ActionTypesApi, {
  ActionTypesApi as IActionTypesApi
} from "../../../../../../services/api/v2/actionTypes/ActionTypes.api";
import AudiencesApi, { AudiencesApi as IAudiencesApi } from "../../../../../../services/api/v2/audiences/Audiences.api";

export class ActionsGridModel {
  organisationId: number;
  projectId: number;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionsProvider: IActionsApi;
  impactsProvider: IImpactsApi;
  audiencesProvider: IAudiencesApi;
  projectStakeholderProvider: IProjectStakeholdersApi;
  actionTypesProvider: IActionTypesApi;
  authUser: FP.Entities.IUser;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  userCanViewActions: boolean;
  userCanEditActions: boolean;
  userCanViewImpacts: boolean;
  userCanViewStakeholders: boolean;
  type: GridTypes = GridTypes.IMPACT_ACTION_GRID;
  actionsGridColumnBuilder: ActionsGridColumnBuilder;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable confirmationService: IModalContextModel;
  @observable selectedItems: number[] = [];
  toasterService: IToasterService;
  @observable isDuplicatingItems: boolean = false;
  microActionForm: SingleFormModel;
  gridApi: GridApi;
  @observable.ref gridActions: IGridUiAction[] = [];
  gridModalBuilder: GridModalBuilder;
  gridToastService: IGridToastService;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;
  @observable projectTeamMembers: any[];
  gridColumns: ColDef<any, any>[];
  urlSearchParams: URLSearchParams;
  gridToolbarType: AppGridToolbarType;
  @observable actionTypes: FP.Entities.IActionType[];
  gridType: EntityTypes | GridTypes;
  @observable isLoading: boolean = true;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    urlSearchParams: URLSearchParams,
    gridToolbarType: AppGridToolbarType,
    preSelectedItemIds: number[],
    gridType: EntityTypes | GridTypes
  ) {
    makeObservable(this);
    this.actionsProvider = ActionsApi;
    this.impactsProvider = ImpactsApi;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.audiencesProvider = AudiencesApi;
    this.actionTypesProvider = ActionTypesApi;
    this.userCanViewActions = PermissionsContext.canViewField(PermissionFields.ACTIONS, organisationId, projectId);
    this.userCanEditActions = PermissionsContext.canEditField(PermissionFields.ACTIONS, organisationId, projectId);
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.urlSearchParams = urlSearchParams;
    this.gridToolbarType = gridToolbarType;
    this.modalService = ModalContext;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.setMicroAddForm();
    this.gridToastService = GridToastService;
    this.gridModalBuilder = new GridModalBuilder();
    this.selectedItems = preSelectedItemIds;
    this.gridType = gridType;
    this.generateAddons();
  }

  loadInitData = async () => {
    if (this.userCanEditActions && this.gridToolbarType !== "link-modal") {
      await this.loadProjectTeamMembers();
      await this.loadActionTypes();
      this.generateFn();
    }
  };

  @action
  onMount = async () => {
    this.generateFn();
    await this.loadInitData();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  onUnmount = () => {};

  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, EntityTypes.ACTIONS);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: EntityTypes.ACTIONS,
      filterHasChangedFn: this.setIsFilterChanged,
      urlSearchParams: this.urlSearchParams
    });

    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: EntityTypes.ACTIONS,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  generateGridConfig = () => {
    this.actionsGridColumnBuilder = new ActionsGridColumnBuilder({
      canEdit: this.userCanEditActions && this.gridToolbarType !== "link-modal",
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewActions: this.userCanViewActions,
      projectTeamMembers: this.projectTeamMembers,
      actionTypes: this.actionTypes,
      columns: [
        CommonColDefFieldNamesEnum.Selected,
        ActionColDefFieldNamesEnum.RagStatus,
        CommonColDefFieldNamesEnum.RefNumber,
        CommonColDefFieldNamesEnum.Name,
        CommonColDefFieldNamesEnum.Description,
        CommonColDefFieldNamesEnum.ProgressStatus,
        CommonColDefFieldNamesEnum.StartDate,
        CommonColDefFieldNamesEnum.EndDate,
        CommonColDefFieldNamesEnum.Owner,
        ActionColDefFieldNamesEnum.AssignedTo,
        ActionColDefFieldNamesEnum.ProjectStakeholderOwners,
        ActionColDefFieldNamesEnum.ActionTypeName,
        CommonColDefFieldNamesEnum.NoteCount,
        CommonColDefFieldNamesEnum.Tags,
        // this.userCanViewStakeholders && ActionColDefFieldNamesEnum.Audiences,
        this.userCanViewImpacts && CommonColDefFieldNamesEnum.Impacts,
        this.userCanViewImpacts && CommonColDefFieldNamesEnum.Processes,
        this.userCanViewImpacts && ImpactColDefFieldNamesEnum.BusinessAreas,
        this.userCanViewStakeholders && ActionColDefFieldNamesEnum.ActionStakeholders,
        this.userCanViewStakeholders && ActionColDefFieldNamesEnum.ActionAudiences,
        CommonColDefFieldNamesEnum.ReviewedBy,
        CommonColDefFieldNamesEnum.ReviewedOn,
        CommonColDefFieldNamesEnum.NextReviewDue,
        CommonColDefFieldNamesEnum.CreatedBy,
        CommonColDefFieldNamesEnum.CreatedAt,
        CommonColDefFieldNamesEnum.ModifiedBy,
        CommonColDefFieldNamesEnum.UpdatedAt
      ]
    });
    this.gridColumns = this.actionsGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetActionsGridActions(this));
  };

  @action
  loadActionTypes = async () => {
    let res = await this.actionTypesProvider.getByOrganisationId(this.organisationId);
    if (!res || res.isError) return;

    this.actionTypes = res.payload;
  };

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.setProjectTeamMembers(res.payload);
  };

  @action
  setProjectTeamMembers = projectTeamMembers => {
    this.projectTeamMembers = projectTeamMembers;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  @action
  deleteFieldData = async (itemId: number, field: ActionField) => {
    const res = await this.actionsProvider.deleteField(this.organisationId, this.projectId, itemId, field);
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  duplicateItems = async () => {
    this.httpProgress.showTopProgressBarVisible();
    this.isDuplicatingItems = true;
    // await this.actionsProvider.duplicateActions(this.organisationId, this.projectId, this.selectedItems);
    this.httpProgress.hideTopProgressBarVisible();
    this.isDuplicatingItems = false;
  };

  reviewItems = async (itemIds: number[], comment: string) => {
    let res = await this.actionsProvider.reviewRange(this.organisationId, this.projectId, itemIds, comment);

    if (!res || res.isError) return;
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.actionsProvider.deleteRange(this.organisationId, this.projectId, itemIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  @action
  setSelectedNodes = (gridApi: GridApi) => {
    const nodesToSelect: IRowNode[] = [];
    gridApi.forEachNode(node => {
      if (!!this.selectedItems?.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    gridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-actions-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedItems && this.selectedItems.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  @action
  setMicroAddForm = () => {
    this.microActionForm = new SingleFormModel();
    this.microActionForm.formFields = getEntityNameMicroFormFields(
      this.createMicroAction,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.action") })
    );
  };

  @action
  createMicroAction = async () => {
    let microActionFormRes = await this.microActionForm.submit();
    this.microActionForm.isSaving = true;
    if (!microActionFormRes) return;

    microActionFormRes = { ...microActionFormRes, projectId: this.projectId, impacts: [], tags: [] };

    this.httpProgress.showOverlay();
    let actionNameExists = await this.actionsProvider.getFiltered(this.organisationId, this.projectId, {
      filters: `name==${microActionFormRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId==${this.projectId}`
    });

    if (actionNameExists && !actionNameExists.isError && actionNameExists.payload.length) {
      this.httpProgress.hideOverlay();
      let confirmCreateAction = await this.confirmCreateAction(microActionFormRes.name);
      if (!confirmCreateAction) return;
      this.httpProgress.showOverlay();
    }

    const res = {
      ...microActionFormRes
    };
    this.httpProgress.showOverlay();
    const result = await this.actionsProvider.create(this.organisationId, this.projectId, res as FP.Entities.IAction);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const action = result.payload;
    this.microActionForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.action") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return action;
  };

  //#region Grid Action Toolbar Modals Methods [#11111190]
  confirmCreateAction = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(name);
  };

  showActionConfirmReviewModal = () => {
    return SHOW_ACTION_REVIEW_CONFIRM_MODAL(this.selectedItems, this.reviewItems, this.toasterService, () =>
      this.gridApi.deselectAll()
    );
  };

  showActionConfirmDeleteModal = () => {
    return SHOW_ACTION_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  showEmailsModal = () => {
    return this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(STAKEHOLDER_EMAILS_MODAL_CONFIG(this.selectedItems, this.projectId))
      .generateModal();
  };

  showUploaderModal = () => {
    this.gridModalBuilder.constructPopupModal().setModalOptions(ACTION_UPLOADER_MODAL_CONFIG()).generateModal();
  };

  showBulkEditModal = () => {
    this.gridModalBuilder
      .constructSideModal()
      .setModalOptions(
        ACTION_BULK_EDIT_MODAL_CONFIG(
          this.projectId,
          this.organisationId,
          this.selectedItems,
          this.onBulkEditModalClose
        )
      )
      .generateModal();
  };

  onBulkEditModalClose = () => {
    if (this.gridApi) {
      this.gridApi.redrawRows();
    }
    ModalContext.hide();
  };
  //#endregion

  //#region Grid Link Action Modal Methods [#11122290]
  showLinkToImpacts = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(ACTIONS_LINK_TO_IMPACTS_MODAL_CONFIG(this.selectedItems, this.assignActionsToImpacts))
      .generateModal();
  };
  showLinkToStakeholders = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(ACTIONS_LINK_TO_STAKEHOLDERS_MODAL_CONFIG(this.projectId, this.assignStakeholdersToActions))
      .generateModal();
  };
  showLinkToTags = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(ACTIONS_LINK_TO_LABELS_MODAL_CONFIG(this.projectId, this.assignLabelsToActions))
      .generateModal();
  };

  showLinkToAudiences = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(ACTIONS_LINK_TO_AUDIENCES_MODAL_CONFIG(this.organisationId, this.projectId, this.selectedItems))
      .generateModal();
  };

  //#endregion

  //#region Grid Link Action Methods [#11133390]
  assignActionsToImpacts = async impactIds => {
    await this.impactsProvider.addBulkActionsToImpacts(
      this.organisationId,
      this.projectId,
      impactIds,
      this.selectedItems
    );

    this.modalService.hide();
  };

  assignActionsToAudiences = async impactIds => {
    await this.audiencesProvider.addBulkActionsToAudiences(
      this.organisationId,
      this.projectId,
      impactIds,
      this.selectedItems
    );

    this.modalService.hide();
  };

  assignStakeholdersToActions = async (stakeholderIds: number[]) => {
    await this.actionsProvider.assignStakeholderOwners(
      this.organisationId,
      this.projectId,
      this.selectedItems,
      stakeholderIds
    );
    this.modalService.hide();
  };

  assignLabelsToActions = async labels => {
    await this.actionsProvider.assignLabels(this.organisationId, this.projectId, this.selectedItems, labels);
    this.modalService.hide();
  };
  //#endregion
}
