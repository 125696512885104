import { ReactNode } from "react";
import { BusinessAreaReportFilterAddon } from "./addons/BusinessAreaReportFilter_addon";
import { LocationReportFilterAddon } from "./addons/LocationReportFilter_addon";
import { ImpactTypeReportFilterAddon } from "./addons/ImpactTypeReportFilter_addon";
import { ImpactGridReportFilterAddon } from "./addons/ImpactGridReportFilter_addon";
import { AudienceGridReportFilterAddon } from "./addons/AudienceGridReportFilte_addon";

export interface IBaseReportFilterAddon {
  key: string;
  selectedIds: number[];
  getSelectedIds: () => number[];
  displayModal: () => void;
  setSelectedIds: (selectedIds: number[]) => void;
  clearSelectedIds: () => void;
  render: () => ReactNode;
}

export enum ReportFilterAddonsKeys {
  BusinessArea = "businessAreaIds",
  Location = "locationIds",
  ImpactType = "impactTypes",
  ImpactGrid = "impactIds",
  AudienceGrid = "audienceIds"
}

export const ReportFilterAddons = {
  [ReportFilterAddonsKeys.BusinessArea]: BusinessAreaReportFilterAddon,
  [ReportFilterAddonsKeys.Location]: LocationReportFilterAddon,
  [ReportFilterAddonsKeys.ImpactType]: ImpactTypeReportFilterAddon,
  [ReportFilterAddonsKeys.ImpactGrid]: ImpactGridReportFilterAddon,
  [ReportFilterAddonsKeys.AudienceGrid]: AudienceGridReportFilterAddon
};
