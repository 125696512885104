import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { PulseAudienceStakeholdersGridModel } from "./AudienceStakeholdersModal_model";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { PulseAudienceStakeholdersGrid } from "../../../PulseAudienceStakeholdersGrid/PulseAudienceStakeholdersGridView_view";
import { Animations } from "../../../../../../../util/Animations";

interface PulseAudienceStakeholdersGridModalProps {
  organisationId: number;
  projectId: number;
  pulseId: number;
  audienceId: number;
  onFieldUpdate: any;
}

export const PulseAudienceStakeholdersGridModal: React.FC<PulseAudienceStakeholdersGridModalProps> = observer(
  ({ organisationId, projectId, pulseId, audienceId, onFieldUpdate }) => {
    const [model] = useState(
      () => new PulseAudienceStakeholdersGridModel(organisationId, +projectId, pulseId, audienceId, onFieldUpdate)
    );

    useEffect(() => {
      model.onMount();

      //eslint-disable-next-line
    }, []);

    if (model.isLoading) return <PositionedSpinner />;
    return (
      <div
        className={`container-fluid ${Animations.FP_ZOOM_IN} speed-4 pulse-audience-stakeholders insight-tab-content__space-filler`}
        style={{ height: "100%" }}
      >
        <PulseAudienceStakeholdersGrid
          refreshDataHandler={model.handleFieldUpdate}
          data={model.stakeholders}
          pulseId={pulseId}
          isLoading={model.isLoading}
          selectedLinkItems={[audienceId]}
          gridToolbarType="link-modal"
        ></PulseAudienceStakeholdersGrid>
      </div>
    );
  }
);
